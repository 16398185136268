import React, { useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { ToastContainer } from 'react-toastify';
import { toastSuccess, toastError } from 'utils/toasts';
import Card from 'components/card';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import {
  MdCancel,
  MdCheckCircle,
  MdChevronLeft,
  MdChevronRight,
  MdEdit,
  MdOutlineError,
} from 'react-icons/md';
import { FiSearch } from 'react-icons/fi';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { IoMdFunnel } from 'react-icons/io';
import { get, post } from 'api';

const ArticlesTable = (props) => {
  const navigate = useNavigate();
  const { columnsData, articles, getAllArticles } = props;
  const [writers, setWriters] = useState([]);

  const fetchWriters = async () => {
    const res = await get('/user/get-users');
    const data = await res.json();
    const writers = data.data.map((writer) => {
      return {
        value: writer.id,
        label: `${writer.firstName} ${writer.lastName}`,
      };
    });
    setWriters(writers);
  };

  useEffect(() => {
    fetchWriters();
  }, [articles]);

  const handleChangeWriter = async (values, articleId) => {
    const body = {
      articleId: articleId,
      writerId: values.value,
    };
    const res = await post('/articles/assign-article', body);
    const data = await res.json();
    if (data.status === 'SUCCESS') {
      toastSuccess('Writer assigned successfully');
      getAllArticles();
    }
  };

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => articles, [articles]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,

    prepareRow,
    nextPage,
    previousPage,

    setGlobalFilter,
    setPageSize,
    state,
  } = tableInstance;

  const { pageSize } = state;

  return (
    <Card extra={'w-full sm:overflow-auto p-4'}>
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
        onChange={(e) => setGlobalFilter(e.target.value)}
        className="flex w-3/4 items-center rounded-[10px] bg-white shadow-2xl shadow-white dark:!bg-navy-800 dark:shadow-none md:mt-2 md:w-1/4"
      >
        <div className="flex h-9 w-full flex-grow items-center rounded-[10px] bg-lightPrimary text-sm text-gray-600 dark:!bg-navy-900">
          <FiSearch className="mx-2 h-4 w-4 !text-gray-700 dark:!text-white" />
          <input
            type="text"
            placeholder="Search...."
            className="block h-full w-full rounded-full bg-lightPrimary text-sm text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
          />
        </div>
      </div>
      <div className="mt-8 h-full max-w-[100%] overflow-x-auto">
        <table
          {...getTableProps()}
          className="w-full table-auto border-collapse whitespace-nowrap"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="border-b border-gray-200 pb-[10px] pr-16 dark:!border-navy-700"
                    key={index}
                  >
                    <div className="text-start text-xs font-bold tracking-wide text-gray-600 lg:text-xs">
                      <div className="flex items-center gap-2">
                        {column.render('Header')}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <BsChevronUp className="mr-1 inline h-4 w-4" />
                          ) : (
                            <BsChevronDown className="mr-1 inline h-4 w-4" />
                          )
                        ) : (
                          <IoMdFunnel className="mr-1 inline h-4 w-4" />
                        )}
                      </div>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={index}
                  className="h-[60px] items-center border-b border-gray-200 dark:!border-white/10"
                >
                  {row.cells.map((cell, index) => {
                    let data = '';
                    if (cell.column.Header === 'ID') {
                      data = (
                        <div className="flex items-center gap-2">
                          <div className="text-sm font-bold text-navy-700 dark:text-white">
                            #{cell.value}
                          </div>
                        </div>
                      );
                    } else if (cell.column.Header === 'DOMAIN NAME') {
                      data = (
                        <div className="flex items-center gap-2">
                          <div className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value}
                          </div>
                        </div>
                      );
                    } else if (cell.column.Header === 'PROJECT NAME') {
                      data = (
                        <div className="flex items-center gap-2">
                          <div className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value}
                          </div>
                        </div>
                      );
                    } else if (cell.column.Header === 'ARTICLE STATUS') {
                      const status = cell.value;
                      const orderStatus = cell.row.original.orderStatus;
                      const articleApproval = cell.row.original.articleApproval;
                      data = (
                        <div className="flex items-center gap-2">
                          <div className={`rounded-full text-xl`}>
                            {status === 'DONE' ? (
                              <MdCheckCircle className="text-green-500" />
                            ) : status === 'CANCELLED' ? (
                              <MdCancel className="text-red-500" />
                            ) : status === 'WRITING' ? (
                              <MdOutlineError className="text-blue-300" />
                            ) : status === 'IN REVISION' ? (
                              <MdOutlineError className="text-orange-400" />
                            ) : (
                              <MdCheckCircle className="text-blue-500" />
                            )}
                          </div>
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {articleApproval && orderStatus === 'DONE'
                              ? 'Published'
                              : articleApproval && status === 'DONE'
                              ? 'Publication approved'
                              : status === 'DONE' &&
                                orderStatus === 'IN PROGRESS'
                              ? 'Draft approved'
                              : orderStatus === 'DRAFT ARTICLE'
                              ? 'Draft sent'
                              : status}
                          </p>
                        </div>
                      );
                    } else if (cell.column.Header === 'WRITER') {
                      const articleId = cell.row.original.id;
                      const writerId = cell.row.original.writerId;
                      const articleWriter = writers.find(
                        (writer) => writer.value === writerId,
                      );
                      data = (
                        <div className="flex items-center gap-2">
                          <Select
                            options={writers}
                            value={
                              articleWriter
                                ? articleWriter
                                : { value: '', label: 'Writer not assigned' }
                            }
                            onChange={(values) =>
                              handleChangeWriter(values, articleId)
                            }
                            name="writer"
                            menuPlacement="bottom"
                            menuPortalTarget={document.body}
                            className="w-2/3"
                          />
                        </div>
                      );
                    } else if (cell.column.Header === 'ACTIONS') {
                      const id = cell.row.original.id;
                      data = (
                        <div className="flex cursor-pointer items-center gap-2">
                          <MdEdit
                            onClick={() =>
                              navigate(`/admin/dashboard/edit-article/${id}`)
                            }
                            className="text-lg text-blue-500 hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-500"
                          />
                        </div>
                      );
                    }
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={index}
                        className="pb-[16px] pt-[14px] sm:text-[14px]"
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* pagination */}
      <div className="mt-10 flex h-[80px] w-full items-center justify-between px-6">
        {/* left side */}
        <div className="flex items-center gap-2">
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="h-10 w-[70px] rounded-xl border border-gray-200 px-2 text-sm  text-gray-600 dark:!border-white/10 dark:!bg-navy-800"
            name=""
            id=""
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
          <p className="> Show rows per page text-sm text-gray-700">
            Showing {pageSize * state.pageIndex + 1}-
            {pageSize * state.pageIndex + pageSize} of {articles.length}
          </p>
        </div>
        {/* right side */}
        <div className="flex items-center gap-2">
          <button
            onClick={() => previousPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronLeft />
          </button>

          <p className="text-sm text-gray-700">
            {state.pageIndex + 1} of {Math.ceil(articles.length / pageSize)}
          </p>

          <button
            onClick={() => nextPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronRight />
          </button>
        </div>
      </div>
    </Card>
  );
};

export default ArticlesTable;
