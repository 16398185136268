import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlDocx from 'html-docx-js/dist/html-docx';
import { saveAs } from 'file-saver';
import { ToastContainer } from 'react-toastify';
import { toastSuccess, toastError } from 'utils/toasts';
import { getFromMarketplace, put, get } from 'api';
import {
  FaEuroSign,
  FaLightbulb,
  FaRegStickyNote,
  FaRegFileWord,
} from 'react-icons/fa';

const ViewArticle = () => {
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const viewId = searchParams.get('viewId');
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [orderDetails, setOrderDetails] = useState([]);
  const [title, setTitle] = useState('');
  const [articleId, setArticleId] = useState();
  const titleRef = useRef();

  const wordCount = useMemo(() => {
    return editorState.getCurrentContent().getPlainText(' ').split(' ').length;
  }, [editorState]);

  const getOrdersFromMarketplace = useCallback(async () => {
    const res = await getFromMarketplace(`/orders/single-order/${articleId}`);
    if (res.status === 200) {
      const json = await res.json();
      if (!json) return;
      setOrderDetails(json);
    }
  }, [articleId]);

  useEffect(() => {
    getOrdersFromMarketplace();
  }, [getOrdersFromMarketplace]);

  useEffect(() => {
    const getArticleFromDb = async () => {
      const res = await get(`/articles/get-view-article/${viewId}`);
      const json = await res.json();
      if (
        json.status === 'SUCCESS' &&
        json.data &&
        json.data[0] &&
        json.data[0].articleTitle &&
        json.data[0].articleContent
      ) {
        const contentState = convertFromRaw(
          JSON.parse(json.data[0].articleContent),
        );
        setEditorState(EditorState.createWithContent(contentState));
        setTitle(json.data[0].articleTitle);
        setArticleId(json.data[0].articleId);
      } else {
        setEditorState(EditorState.createEmpty());
        if (json.status !== 'SUCCESS') {
          toastError('Error fetching article');
        }
      }
    };
    getArticleFromDb();
  }, [viewId]);

  const handleTitleChange = () => {
    setTitle(titleRef.current.value);
  };

  const handleSave = async () => {
    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    const rawContentString = JSON.stringify(rawContent);

    if (wordCount > orderDetails.wordCount) {
      toastError('Word count exceeds the limit');
      return;
    }

    const data = {
      articleId,
      articleTitle: title,
      articleContent: rawContentString,
    };

    const res = await put('/articles/update-view-article', data);
    const json = await res.json();
    if (json.status === 'SUCCESS') {
      getOrdersFromMarketplace();
      toastSuccess('Article updated successfully');
    } else {
      toastError('Error updating article');
    }
  };

  const handleChange = (newEditorState) => {
    const newContentState = newEditorState.getCurrentContent();
    const newWordCount = newContentState.getPlainText(' ').split(' ').length;

    if (newWordCount <= orderDetails.wordCount) {
      setEditorState(newEditorState);
    } else {
      toastError('Word count exceeds the limit');
    }
  };

  const htmlData = useMemo(() => {
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  }, [editorState]);

  const exportToWord = (htmlContent) => {
    const converted = htmlDocx.asBlob(htmlContent);
    saveAs(converted, `${title}.docx`);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(titleRef.current.value);
  };

  return (
    <div className="h-full w-full rounded-[20px]  px-3 pt-7 md:px-8">
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="!z-5 relative flex w-full flex-col rounded-[20px] bg-white bg-clip-border !p-5 shadow-3xl  shadow-shadow-100 dark:!bg-navy-800  dark:text-white dark:shadow-none md:w-full">
        <div className="flex items-center justify-evenly">
          <div className="flex w-[55%] flex-col">
            <h1 className="mb-4 text-2xl font-bold">Edit Article</h1>
            <div className="mb-4 flex flex-col">
              <h2 className="mb-2 font-bold">Article Title</h2>
              <div className="flex items-center gap-2">
                <input
                  ref={titleRef}
                  className="focus:border-transparent w-full rounded-[12px] border-2 border-gray-100 p-2 focus:outline-none focus:ring-2 focus:ring-navy-600"
                  type="text"
                  placeholder="Article Title"
                  onChange={handleTitleChange}
                  value={title}
                />
                <button
                  className="rounded-[12px] bg-navy-600 p-2 text-white hover:bg-navy-800"
                  onClick={copyToClipboard}
                >
                  Copy
                </button>
              </div>
            </div>
            <h2 className="mb-2 font-bold">Article Content</h2>
            <div className="editorJs flex items-center rounded-[12px] border-2 border-gray-100 p-4">
              <Editor
                editorState={editorState}
                onEditorStateChange={handleChange}
              />
            </div>
            <div className="mt-2 flex justify-end gap-2">
              <button
                className="rounded-[12px] bg-navy-600 px-4 py-2 text-white hover:bg-navy-800"
                onClick={handleSave}
              >
                Save
              </button>
              <button
                className="rounded-[12px] bg-green-500 px-4 py-2 text-white hover:bg-green-700"
                onClick={() => exportToWord(htmlData)}
              >
                Download as .docx file
              </button>
            </div>
          </div>
          <div className="w-[30%] rounded-[12px] border-2 border-gray-100 p-4">
            <h2 className="mb-4 font-bold">Article Details</h2>
            <div className="mb-2 flex items-center gap-2">
              <FaEuroSign />
              <span className="text-md text-black dark:text-white">
                Article price:{' '}
              </span>
              <span className="text-md text-navy-700 dark:text-white">
                {orderDetails.articlePrice} Eur
              </span>
            </div>
            <div className="mb-2 flex items-center gap-2">
              <FaLightbulb />
              <span className="text-md text-black dark:text-white">
                Topic suggestion:{' '}
              </span>
              <span className="text-md text-navy-700 dark:text-white">
                {orderDetails.topicSuggestion}
              </span>
            </div>
            <div className="mb-2 flex flex-col gap-2">
              <div className="flex items-center gap-2">
                <FaRegStickyNote />
                <span className="text-md text-black dark:text-white">
                  Instructions:
                </span>
              </div>
              <span className="text-md text-navy-700 dark:text-white">
                {orderDetails.instructions}
              </span>
            </div>
            <div className="mb-2 flex items-center gap-2">
              <FaRegFileWord />
              <span className="text-md text-black dark:text-white">
                Word count:{' '}
              </span>
              <span className="text-md text-navy-700 dark:text-white">
                {orderDetails.wordCount}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <FaRegFileWord />
              <span className="text-md text-black dark:text-white">
                Current Word Count:{' '}
              </span>
              <span className="text-md text-navy-700 dark:text-white">
                <span
                  className={
                    wordCount > orderDetails.wordCount
                      ? 'text-red-500'
                      : 'text-green-500'
                  }
                >
                  {wordCount}
                </span>{' '}
                / {orderDetails.wordCount}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewArticle;
