import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'components/dropdown';
import { FiAlignJustify } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import navbarimage from 'assets/img/layout/Navbar.png';
import { BsArrowBarUp, BsBank } from 'react-icons/bs';
import { FiSearch } from 'react-icons/fi';
import Configurator from './Configurator';
import {
  IoMdNotificationsOutline,
  IoMdInformationCircleOutline,
} from 'react-icons/io';
import { RiMoonFill, RiSunFill } from 'react-icons/ri';
import { get, getUserId } from 'api';
import avatar from 'assets/img/logo/Icon.png';

const Navbar = (props) => {
  const navigate = useNavigate();
  const [currentBalance, setCurrentBalance] = useState(0);
  const [userFirstName, setUserFirstName] = useState('');
  const {
    onOpenSidenav,
    brandText,
    mini,
    // setMini,
    // theme,
    // setTheme,
    hovered,
  } = props;
  const [darkmode, setDarkmode] = React.useState(
    document.body.classList.contains('dark'),
  );
  const currencySymbol = localStorage.getItem('currency');

  useEffect(() => {
    const fetchProfile = async () => {
      const userId = getUserId();
      await get(`/user/profile/${userId}`)
        .then((res) => res.json())
        .then((data) => {
          setUserFirstName(data.data[0].firstName);
        });
    };
    fetchProfile();
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
  };

  return (
    <nav
      className={`duration-175 linear fixed right-3 top-3 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/30 transition-all ${
        mini === false
          ? 'w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_307px)] 2xl:w-[calc(100vw_-_322px)]'
          : mini === true && hovered === true
          ? 'w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_307px)] 2xl:w-[calc(100vw_-_322px)]'
          : 'w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_180px)] 2xl:w-[calc(100vw_-_195px)]'
      }  p-2 backdrop-blur-xl dark:bg-[#0b14374d] md:right-[30px] md:top-4 xl:top-[20px]`}
    >
      <div className="ml-[6px]">
        <div className="h-6 w-[224px] pt-1">
          <Link
            className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            to="/admin/dashboard/articles"
          >
            Pages
            <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
              {' '}
              /{' '}
            </span>
          </Link>
          <Link
            className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            to="#"
          >
            {brandText}
          </Link>
        </div>
        <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
          <Link
            to="#"
            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          >
            {brandText}
          </Link>
        </p>
      </div>

      <div className="relative mt-[3px] flex h-[61px] w-[255px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[165px] md:flex-grow-0 md:gap-1 xl:w-[165px] xl:gap-2">
        <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span>
        <p className="text-md font-medium text-gray-700 dark:text-white">
          {userFirstName}
        </p>
        <Dropdown
          button={
            <img
              className="h-10 w-10 cursor-pointer rounded-full"
              src={avatar}
              alt="Elon Musk"
            />
          }
          children={
            <div className="flex h-max w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat pb-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="ml-4 mt-3">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    👋 Hey, {userFirstName}
                  </p>{' '}
                </div>
              </div>
              <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />

              <div className="ml-4 mt-3 flex flex-col">
                <Link
                  to="/admin/account/settings"
                  className="text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Profile Settings
                </Link>
                <button
                  onClick={() => handleLogout()}
                  className="mt-3 text-left text-sm font-medium text-red-500 hover:text-red-500"
                >
                  Log Out
                </button>
              </div>
            </div>
          }
          classNames={'py-2 top-8 -left-[180px] w-max'}
        />
      </div>
    </nav>
  );
};

export default Navbar;
