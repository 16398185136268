import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import InputField from 'components/fields/InputField';
import { getFromMarketplace } from 'api';

const CompanyAddress = ({ billingInfo, updateBillingInfo }) => {
  const { address1, address2, city, country, state, zipCode } = billingInfo;
  const [countries, setCountries] = useState([]);

  const getCountries = async () => {
    await getFromMarketplace('/admin/countries').then((res) =>
      res.json().then((data) => {
        setCountries(
          data
            .sort((a, b) => a.country.localeCompare(b.country))
            .map((item) => ({
              value: item.id,
              label: item.country,
            })),
        );
      }),
    );
  };

  useEffect(() => {
    getCountries();
  }, []);

  const handleInputChange = (e, isCountry) => {
    if (isCountry) {
      updateBillingInfo({ country: e.label });
      return;
    }
    const { name, value } = e.target;
    updateBillingInfo({ [name]: value });
  };

  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
      {/* Header */}
      <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        Your billing address
      </h4>

      {/* content */}
      <div className="mt-4">
        <InputField
          extra="mb-3"
          label="Address Line 1"
          placeholder="eg. Main Street 203"
          id="address1"
          type="text"
          name="address1"
          onChange={handleInputChange}
          value={address1 || ''}
        />
        <InputField
          extra="mb-3"
          label="Address Line 2"
          placeholder="eg. Apartment, Floor"
          id="address2"
          type="text"
          name="address2"
          onChange={handleInputChange}
          value={address2 || ''}
        />

        {/* inputs */}
        <div className="grid grid-cols-1 gap-3">
          <div className="col-span-1 grid grid-cols-2 gap-3">
            <InputField
              label="City"
              placeholder="eg. Miami"
              id="city"
              type="text"
              name="city"
              onChange={handleInputChange}
              value={city || ''}
            />
            <div>
              <label
                htmlFor={country}
                className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
              >
                Country
              </label>
              <Select
                id="country"
                label="Country"
                name="country"
                options={countries}
                value={countries.find((obj) => obj.label === country)}
                onChange={(value) => handleInputChange(value, true)}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '3rem',
                    minHeight: '3rem',
                    borderRadius: '0.75rem',
                    borderColor: '#ccc',
                    borderWidth: '1px',
                    backgroundColor: '#FFFFFF',
                    marginTop: '8px',
                    '&:hover': {
                      borderColor: '#ccc',
                      boxShadow: 'none',
                    },
                  }),
                }}
              />
            </div>
          </div>
          <div className="col-span-1 grid grid-cols-2 gap-3">
            <InputField
              label="State"
              placeholder="Florida"
              id="state"
              type="text"
              name="state"
              onChange={handleInputChange}
              value={state || ''}
            />
            <InputField
              label="Zip Code"
              placeholder="eg. 33101"
              id="zipCode"
              type="text"
              name="zipCode"
              onChange={handleInputChange}
              value={zipCode || ''}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyAddress;
