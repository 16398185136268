import React, { useState, useEffect } from 'react';
import Information from './components/Information';
import DeleteProfile from './components/Delete';
import Password from './components/Password';
import Profile from './components/Profile';
import Upload from './components/Upload';
import { get, getUserId } from 'api';

const Setting = () => {
  const [user, setUser] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      const userId = getUserId();
      await get(`/user/profile/${userId}`)
        .then((res) => res.json())
        .then((data) => {
          setUser(data.data[0]);
          setLoaded(true);
        });
    };
    fetchUser();
  }, []);

  if (!loaded) {
    return null;
  }
  return (
    <div className="flex w-[100%] flex-col gap-5 md:w-[75%]">
      <Profile user={user} />
      <Information user={user} />
      {/* <Upload /> */}
      <Password />
      <DeleteProfile />
    </div>
  );
};

export default Setting;
