export const articleHeaders = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'DOMAIN NAME',
    accessor: 'domain',
  },
  {
    Header: 'PROJECT NAME',
    accessor: 'projectName',
  },
  {
    Header: 'ARTICLE STATUS',
    accessor: 'articleStatus',
  },
  {
    Header: 'WRITER',
    accessor: 'writer',
  },
  {
    Header: 'ACTIONS',
    accessor: 'delete',
  },
];
