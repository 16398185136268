const Footer = () => {
  return (
    <div className="flex w-full flex-col items-center justify-between px-1 pb-8 pt-3 lg:px-8 xl:flex-row">
      <div className="mb-4 text-center text-sm font-medium text-gray-600 sm:!mb-0 md:text-lg">
        ©{1900 + new Date().getYear()} Linkjuice market. All Rights Reserved.
      </div>
      <div>
        <ul className="flex flex-wrap items-center sm:flex-nowrap">
          <li className="mr-12">
            <a
              target="blank"
              href="mailto:hello@linkjuice.market"
              className="text-sm text-gray-600 hover:text-gray-600 md:text-base"
            >
              Support
            </a>
          </li>
          <li className="mr-12">
            <a
              target="blank"
              href="https://linkjuice.market/privacy-policy"
              className="text-sm text-gray-600 hover:text-gray-600 md:text-base"
            >
              Privacy Policy
            </a>
          </li>
          <li className="mr-12">
            <a
              target="blank"
              href="https://linkjuice.market/terms-of-service"
              className="text-sm text-gray-600 hover:text-gray-600 md:text-base"
            >
              Terms of Service
            </a>
          </li>
          <li>
            <a
              target="blank"
              href="https://linkjuice.market/blog"
              className="text-sm text-gray-600 hover:text-gray-600 md:text-base"
            >
              Blog
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
