export const getUserId = () => {
  return localStorage.getItem('userId');
};

export const getCurrentProjectId = () => {
  const project = localStorage.getItem('currentProject');
  return project ? JSON.parse(project).id : null;
};

export const getUserRole = () => {
  return localStorage.getItem('userRole');
};

export const getIsUserActivated = () => {
  const userActivated = localStorage.getItem('userActivated');
  if (userActivated === 'true') {
    return true;
  } else {
    return false;
  }
};

export const get = (path) => {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}${path}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
};

export const getFromMarketplace = (path) => {
  return fetch(`${process.env.REACT_APP_MARKETPLACE_BACKEND_URL}${path}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
};

export const post = (path, body) => {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}${path}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify(body),
  });
};

export const put = (path, body) => {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}${path}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify(body),
  });
};

export const putMarketplace = (path, body) => {
  return fetch(`${process.env.REACT_APP_MARKETPLACE_BACKEND_URL}${path}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify(body),
  });
};

export const remove = (path) => {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}${path}`, {
    method: 'DELETE',
  });
};

export const upload = (path, body) => {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}${path}`, {
    method: 'POST',
    body: body,
  });
};
