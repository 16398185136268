import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import { useParams } from 'react-router-dom';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlDocx from 'html-docx-js/dist/html-docx';
import { saveAs } from 'file-saver';
import { ToastContainer } from 'react-toastify';
import { toastSuccess, toastError } from 'utils/toasts';
import { v4 as uuidv4 } from 'uuid';
import { getFromMarketplace, put, getUserId, get, putMarketplace } from 'api';
import {
  FaEuroSign,
  FaLightbulb,
  FaRegStickyNote,
  FaRegFileWord,
  FaPen,
  FaCommentDots,
  FaCheckCircle,
} from 'react-icons/fa';

const EditArticle = () => {
  const { id } = useParams();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [orderDetails, setOrderDetails] = useState([]);
  const [title, setTitle] = useState('');
  const [articleViewUrl, setArticleViewUrl] = useState('');
  const [publicationUrl, setPublicationUrl] = useState('');
  const userId = getUserId();
  const titleRef = useRef(null);
  const publicationUrlRef = useRef(null);

  console.log(articleViewUrl);

  const wordCount = useMemo(() => {
    return editorState.getCurrentContent().getPlainText(' ').split(' ').length;
  }, [editorState]);

  const getOrdersFromMarketplace = useCallback(async () => {
    const res = await getFromMarketplace(`/orders/single-order/${id}`);
    if (res.status === 200) {
      const json = await res.json();
      if (!json) return;
      setOrderDetails(json);
    }
  }, [id]);

  useEffect(() => {
    getOrdersFromMarketplace();
  }, [getOrdersFromMarketplace]);

  useEffect(() => {
    const getArticleFromDb = async () => {
      const res = await get(`/articles/get-article/${id}`);
      const json = await res.json();
      if (
        json.status === 'SUCCESS' &&
        json.data &&
        json.data[0] &&
        json.data[0].articleTitle &&
        json.data[0].articleContent
      ) {
        const contentState = convertFromRaw(
          JSON.parse(json.data[0].articleContent),
        );
        setEditorState(EditorState.createWithContent(contentState));
        setTitle(json.data[0].articleTitle);
        setArticleViewUrl(json.data[0].articleViewUrl);
        setPublicationUrl(json.data[0].publicationUrl);
      } else {
        setEditorState(EditorState.createEmpty());
        if (json.status !== 'SUCCESS') {
          toastError('Error fetching article');
        }
      }
    };
    getArticleFromDb();
  }, [id]);

  const handleTitleChange = () => {
    setTitle(titleRef.current.value);
  };

  const handlePublicationUrlChange = () => {
    setPublicationUrl(publicationUrlRef.current.value);
  };

  const handleSave = async () => {
    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    const rawContentString = JSON.stringify(rawContent);

    if (wordCount > orderDetails.wordCount) {
      toastError('Word count exceeds the limit');
      return;
    }

    const uniqueId = uuidv4();
    const articleViewUrl = `${process.env.REACT_APP_URL}/view-article?viewId=${uniqueId}`;

    const data = {
      articleId: id,
      articleTitle: title,
      articleContent: rawContentString,
      writerId: userId,
      articleViewUrl,
      articleViewId: uniqueId,
      publicationUrl: publicationUrl,
    };
    const res = await put('/articles/update-article', data);
    const json = await res.json();
    if (json.status === 'SUCCESS') {
      getOrdersFromMarketplace();
      toastSuccess('Article updated successfully');
    } else {
      toastError('Error updating article');
    }
  };

  const handleChange = (newEditorState) => {
    const newContentState = newEditorState.getCurrentContent();
    const newWordCount = newContentState.getPlainText(' ').split(' ').length;

    if (newWordCount <= orderDetails.wordCount) {
      setEditorState(newEditorState);
    } else {
      toastError('Word count exceeds the limit');
    }
  };

  const handleDraftSend = async () => {
    const body = {
      articleStatus: 'ARTICLE READY',
      orderStatus: 'DRAFT ARTICLE',
      publicationUrl: articleViewUrl,
    };

    const res = await putMarketplace(`/orders/status/${id}`, body);
    if (res.status === 200) {
      getOrdersFromMarketplace();
      toastSuccess('Draft sent for review');
    } else {
      toastError('Error sending draft for review');
    }
  };

  const handlePublicationSend = async () => {
    const body = {
      articleStatus: 'DONE',
      orderStatus: 'PUBLICATION URL',
      publicationUrl: articleViewUrl,
    };
    const res = await putMarketplace(`/orders/status/${id}`, body);
    if (res.status === 200) {
      getOrdersFromMarketplace();
      toastSuccess('Publication sent for review');
    } else {
      toastError('Error sending draft for review');
    }
  };

  const handlePublishedUrlSend = async () => {
    const body = {
      articleStatus: 'DONE',
      orderStatus: 'DONE',
      publicationUrl: publicationUrl,
    };
    const res = await putMarketplace(`/orders/status/${id}`, body);
    if (res.status === 200) {
      getOrdersFromMarketplace();
      toastSuccess('Published article url sent to customer');
    } else {
      toastError('Error sending draft for review');
    }
  };

  const htmlData = useMemo(() => {
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  }, [editorState]);

  const exportToWord = (htmlContent) => {
    const converted = htmlDocx.asBlob(htmlContent);
    saveAs(converted, `${title}.docx`);
  };

  const copyTitleToClipboard = () => {
    if (titleRef && titleRef.current) {
      navigator.clipboard.writeText(titleRef.current.value);
    }
  };

  const copyUrlToClipboard = () => {
    if (publicationUrlRef && publicationUrlRef.current) {
      navigator.clipboard.writeText(publicationUrlRef.current.value);
    }
  };

  return (
    <div className="h-full w-full rounded-[20px]  px-3 pt-7 md:px-8">
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="!z-5 relative flex w-full flex-col rounded-[20px] bg-white bg-clip-border !p-5 shadow-3xl  shadow-shadow-100 dark:!bg-navy-800  dark:shadow-none md:w-full">
        <div className="flex items-center justify-evenly">
          <div className="flex w-[55%] flex-col">
            <h1 className="mb-4 text-2xl font-bold">Edit Article</h1>
            <div className="mb-4 flex flex-col">
              <h2 className="mb-2 font-bold">Article Title</h2>
              <div className="flex items-center gap-2">
                <input
                  ref={titleRef}
                  className="focus:border-transparent w-full rounded-[12px] border-2 border-gray-100 p-2 focus:outline-none focus:ring-2 focus:ring-navy-600"
                  type="text"
                  placeholder="Article Title"
                  onChange={handleTitleChange}
                  value={title || ''}
                />
                <button
                  className="rounded-[12px] bg-brand-500 p-2 text-white hover:opacity-80"
                  onClick={copyTitleToClipboard}
                >
                  Copy
                </button>
              </div>
            </div>
            <h2 className="mb-2 font-bold">Article Content</h2>
            <div className="editorJs flex items-center rounded-[12px] border-2 border-gray-100 p-4">
              <Editor
                editorState={editorState}
                onEditorStateChange={handleChange}
              />
            </div>
            {orderDetails.articleStatus === 'DONE' &&
              orderDetails.articleApproval && (
                <div className="mb-4 flex flex-col">
                  <h2 className="mb-2 mt-2 font-bold">Published Article URL</h2>
                  <div className="flex items-center gap-2">
                    <input
                      ref={publicationUrlRef}
                      className="focus:border-transparent w-full rounded-[12px] border-2 border-gray-100 p-2 focus:outline-none focus:ring-2 focus:ring-navy-600"
                      type="text"
                      placeholder="Published Article URL"
                      onChange={handlePublicationUrlChange}
                      value={publicationUrl || ''}
                    />
                    <button
                      className="rounded-[12px] bg-navy-600 p-2 text-white hover:bg-navy-800"
                      onClick={copyUrlToClipboard}
                    >
                      Copy
                    </button>
                  </div>
                </div>
              )}
            <div className="mt-2 flex justify-between gap-2">
              <div>
                <button
                  className="rounded-[12px] bg-brand-500 px-4 py-2 text-white hover:opacity-80"
                  onClick={handleSave}
                >
                  Save
                </button>
                {orderDetails.orderStatus === 'IN PROGRESS' &&
                  orderDetails.articleStatus === 'WRITING' && (
                    <button
                      className="ml-2 rounded-[12px] bg-brand-500 px-4 py-2 text-white hover:opacity-80"
                      onClick={handleDraftSend}
                    >
                      Send draft for review
                    </button>
                  )}
                {orderDetails.orderStatus === 'IN PROGRESS' &&
                  (orderDetails.articleStatus === 'DONE' ||
                    orderDetails.articleStatus === 'IN REVISION') && (
                    <button
                      className="ml-2 rounded-[12px] bg-navy-600 px-4 py-2 text-white hover:bg-navy-800"
                      onClick={handlePublicationSend}
                    >
                      Send publication for review
                    </button>
                  )}
                {orderDetails.articleApproval &&
                  orderDetails.articleStatus === 'DONE' && (
                    <button
                      className="ml-2 rounded-[12px] bg-navy-600 px-4 py-2 text-white hover:bg-navy-800"
                      onClick={handlePublishedUrlSend}
                    >
                      Send published article url to customer
                    </button>
                  )}
              </div>
              <button
                className="rounded-[12px] bg-green-500 px-4 py-2 text-white hover:opacity-80"
                onClick={() => exportToWord(htmlData)}
              >
                Download as .docx file
              </button>
            </div>
          </div>
          <div className="w-[30%] rounded-[12px] border-2 border-gray-100 p-4">
            <h2 className="mb-4 font-bold">Article Details</h2>
            <div className="mb-2 flex items-center gap-2">
              <FaEuroSign className="text-brand-500" />
              <span className="text-md text-black font-semibold">
                Article price:{' '}
              </span>
              <span className="text-md text-navy-700">
                {orderDetails.articlePrice} Eur
              </span>
            </div>
            <div className="mb-2 flex-col items-center gap-2">
              <div className="flex items-center gap-2">
                <FaLightbulb className="text-brand-500" />
                <span className="text-md text-black font-semibold">
                  Topic suggestion:{' '}
                </span>
              </div>
              <span className="text-md text-navy-700">
                {orderDetails.topicSuggestion}
              </span>
            </div>
            <div className="mb-2 flex-col gap-2">
              <div className="flex items-center gap-2">
                <FaRegStickyNote className="text-brand-500" />
                <span className="text-md text-black font-semibold">
                  Instructions:
                </span>
              </div>
              <span className="text-md text-navy-700">
                {orderDetails.instructions}
              </span>
            </div>
            <div className="mb-2 flex items-center gap-2">
              <FaRegFileWord className="text-brand-500" />
              <span className="text-md text-black font-semibold">
                Word count:{' '}
              </span>
              <span className="text-md text-navy-700">
                {orderDetails.wordCount}
              </span>
            </div>
            <div className="mb-2 flex-col gap-2">
              <div className="flex items-center gap-2">
                <FaRegFileWord className="text-brand-500" />
                <span className="text-md text-black font-semibold">
                  Current Word Count:{' '}
                </span>
              </div>
              <span className="text-md text-navy-700">
                <span
                  className={
                    wordCount > orderDetails.wordCount
                      ? 'text-red-500'
                      : 'text-green-500'
                  }
                >
                  {wordCount}
                </span>{' '}
                / {orderDetails.wordCount} (remaining:{' '}
                {orderDetails.wordCount - wordCount})
              </span>
            </div>
            <div className="mb-2 flex items-center gap-2">
              <FaPen
                className={
                  orderDetails.orderStatus === 'IN PROGRESS'
                    ? 'text-brand-500'
                    : orderDetails.orderStatus === 'DRAFT ARTICLE'
                    ? 'text-orange-300'
                    : 'text-green-500'
                }
              />
              <span className="text-md text-black font-semibold">
                Article Status:{' '}
              </span>
              <span className="text-md text-navy-700">
                <span
                  className={
                    orderDetails.orderStatus === 'IN PROGRESS'
                      ? 'text-brand-500'
                      : orderDetails.orderStatus === 'DRAFT ARTICLE'
                      ? 'text-orange-300'
                      : 'text-green-500'
                  }
                >
                  {orderDetails.orderStatus === 'IN PROGRESS' &&
                  orderDetails.articleStatus === 'DONE'
                    ? 'Draft approved'
                    : orderDetails.orderStatus === 'IN PROGRESS' &&
                      orderDetails.articleStatus !== 'DONE' &&
                      orderDetails.articleStatus !== 'IN REVISION'
                    ? 'Writing'
                    : orderDetails.orderStatus === 'DRAFT ARTICLE'
                    ? 'Draft article sent for review'
                    : orderDetails.orderStatus === 'PUBLICATION URL'
                    ? 'Publication URL sent for review'
                    : orderDetails.articleStatus === 'IN REVISION'
                    ? 'In revision'
                    : orderDetails.orderStatus === 'CANCELLED'
                    ? 'Cancelled'
                    : 'Done'}
                </span>
              </span>
            </div>
            {orderDetails.articleRevision && (
              <div className="mb-2 flex-col gap-2">
                <div className="flex items-center gap-2">
                  <FaCommentDots className="text-brand-500" />
                  <span className="text-md text-black font-semibold">
                    Revision comments:{' '}
                  </span>
                </div>
                <span className="text-md text-navy-700">
                  {orderDetails.articleRevision}
                </span>
              </div>
            )}
            <div className="flex items-center gap-2">
              <FaCheckCircle
                className={
                  !orderDetails.articleApproval
                    ? 'text-red-500'
                    : 'text-green-500'
                }
              />
              <span className="text-md text-black font-semibold">
                Article Approval:{' '}
              </span>
              <span className="text-md text-navy-700">
                <span
                  className={
                    !orderDetails.articleApproval
                      ? 'text-red-500'
                      : 'text-green-500'
                  }
                >
                  {orderDetails.articleApproval
                    ? 'Approved'
                    : 'Not Yet Approved'}
                </span>
              </span>
            </div>
            <div className="mt-1 flex items-center gap-2">
              <FaCheckCircle className={'text-blue-500'} />
              <span className="text-md text-black font-semibold">
                Article Preview URL:{' '}
              </span>

              <span className="text-md text-blue-500 underline">
                <a href={articleViewUrl} target="_blank" rel="noreferrer">
                  Article Preview
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditArticle;
