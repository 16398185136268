import React, { useState, useEffect } from 'react';
import { getFromMarketplace } from 'api';
import ArticleCard from './components/ArticleCard';
import { get } from 'api';

const Articles = () => {
  const [allOrders, setAllOrders] = useState([]);
  const [allArticles, setAllArticles] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);

  const getAllOrders = async () => {
    const res = await getFromMarketplace(`/orders/all`);
    const data = await res.json();
    if (!data) return;
    const filteredData = data.filter(
      (order) =>
        order.orderStatus !== 'CANCELLED' &&
        order.orderStatus !== 'DONE' &&
        order.articleStatus !== 'WAITING FOR ARTICLE' &&
        order.articleStatus !== 'DONE' &&
        order.articleStatus !== 'IN REVISION',
    );
    setAllOrders(filteredData);
  };

  const getArticles = async () => {
    const res = await get(`/articles/get-articles`);
    const json = await res.json();
    if (json.status === 'SUCCESS') {
      setAllArticles(json.data);
    }
  };

  useEffect(() => {
    getAllOrders();
    getArticles();
  }, []);

  useEffect(() => {
    const articles = allArticles.map((article) => article.articleId);
    const newFilteredOrders = allOrders.filter(
      (order) => !articles.includes(order.id),
    );
    setFilteredOrders(newFilteredOrders);
  }, [allArticles, allOrders]);

  return (
    <div className="h-full w-full rounded-[20px]  px-3 pt-7 md:px-8">
      <div className="!z-5 relative flex w-full flex-col rounded-[20px] bg-white bg-clip-border !p-5 shadow-3xl  shadow-shadow-100 dark:!bg-navy-800  dark:text-white dark:shadow-none md:w-full">
        <div className="grid gap-12 md:grid-cols-3">
          {filteredOrders.map((order) => (
            <ArticleCard
              key={order.id}
              data={order}
              getArticles={getArticles}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Articles;
