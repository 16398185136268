import React, { useState, useEffect } from 'react';
import { getFromMarketplace, get, getUserId } from 'api';
import ArticlesTable from './components/ArticlesTable';
import { articleHeaders } from './variables/columnsData';

const MyArticles = () => {
  const userId = getUserId();
  const [allOrders, setAllOrders] = useState([]);
  const [myArticles, setMyArticles] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);

  useEffect(() => {
    getFromMarketplace(`/orders/all`)
      .then((res) => res.json())
      .then((data) => {
        if (!data) return;
        const newData = data.map((order) => {
          return {
            ...order,
            projectName: order.Project.projectName,
          };
        });
        const filteredData = newData.filter(
          (order) =>
            order.orderStatus !== 'CANCELLED' &&
            order.articleStatus !== 'WAITING FOR ARTICLE',
        );
        setAllOrders(filteredData);
      });
  }, []);

  useEffect(() => {
    const getMyArticles = async () => {
      const res = await get(`/articles/get-articles/${userId}`);
      const json = await res.json();
      if (json.status === 'SUCCESS') {
        setMyArticles(json.data);
      }
    };
    getMyArticles();
  }, [userId]);

  useEffect(() => {
    const myArticleIds = myArticles.map((article) => article.articleId);
    const newFilteredOrders = allOrders.filter((order) =>
      myArticleIds.includes(order.id),
    );
    setFilteredOrders(newFilteredOrders);
  }, [myArticles, allOrders]);

  return (
    <div>
      <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
        <ArticlesTable columnsData={articleHeaders} articles={filteredOrders} />
      </div>
    </div>
  );
};

export default MyArticles;
