import React, { useState, useEffect } from 'react';
import { getFromMarketplace } from 'api';
import ArticlesTable from './components/ArticlesTable';
import { articleHeaders } from './variables/columnsData';
import { get } from 'api';

const AdminArticles = () => {
  const [allOrders, setAllOrders] = useState([]);
  const [allArticles, setAllArticles] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);

  useEffect(() => {
    getFromMarketplace(`/orders/all`)
      .then((res) => res.json())
      .then((data) => {
        if (!data) return;
        const newData = data.map((order) => {
          return {
            ...order,
            projectName: order.Project.projectName,
          };
        });
        const filteredData = newData.filter(
          (order) =>
            order.orderStatus !== 'CANCELLED' &&
            order.articleStatus !== 'WAITING FOR ARTICLE',
        );
        setAllOrders(filteredData);
      });
  }, []);

  const getAllArticles = async () => {
    const res = await get(`/articles/get-all-articles`);
    const json = await res.json();
    if (json.status === 'SUCCESS') {
      setAllArticles(json.data);
    }
  };

  useEffect(() => {
    getAllArticles();
  }, []);

  useEffect(() => {
    const articlesMap = new Map(
      allArticles.map((article) => [article.articleId, article]),
    );
    const merged = allOrders.map((order) => {
      const article = articlesMap.get(order.id);
      return article ? { ...order, ...article } : order;
    });
    setFilteredOrders(merged);
  }, [allArticles, allOrders]);

  return (
    <div>
      <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
        <ArticlesTable
          columnsData={articleHeaders}
          articles={filteredOrders}
          getAllArticles={getAllArticles}
        />
      </div>
    </div>
  );
};

export default AdminArticles;
