import React, { useState } from 'react';
import Card from 'components/card';
import {
  FaEuroSign,
  FaLightbulb,
  FaRegStickyNote,
  FaRegFileWord,
} from 'react-icons/fa';
import { ToastContainer } from 'react-toastify';
import { toastSuccess, toastError } from 'utils/toasts';
import { post, getUserId } from 'api';
import TooltipHorizon from 'components/tooltip';

const ArticleCard = ({ data, getArticles }) => {
  const userId = getUserId();
  const [instructionsCopied, setInstructionCopied] = useState({});

  const handleAssignToMe = async () => {
    const body = {
      articleId: data.id,
      writerId: userId,
    };
    const res = await post(`/articles/assign-article`, body);
    const json = await res.json();
    if (json.status === 'SUCCESS') {
      toastSuccess('Article assigned to you');
      getArticles();
    } else {
      toastError('Error assigning article');
    }
  };

  return (
    <Card extra={'w-full py-4 px-4 h-auto bg-gray-100 rounded-lg shadow-lg'}>
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="flex flex-col gap-3">
        <div className="mb-2 items-center">
          <p
            className="text-md
            text-gray-500
          "
          >
            Id: {data.id}
          </p>
          <h2 className="text-xl font-bold">{data.domain}</h2>
        </div>
        <div className="flex items-center gap-2">
          <FaEuroSign />
          <span className="text-md text-black">Article price: </span>
          <span className="text-sm font-bold text-navy-700">
            {data.articlePrice} Eur
          </span>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <FaLightbulb />
            <span className="text-md text-black">Topic suggestion: </span>
          </div>
          <span className="text-sm font-bold text-navy-700">
            {data.topicSuggestion}
          </span>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <FaRegStickyNote />
            <span className="text-md text-black">Instructions:</span>
          </div>
          <span className="text-md overflow-hidden text-navy-700">
            {instructionsCopied[data.id] && <div>Instructions Copied!</div>}
            <TooltipHorizon
              extra="max-h-[300px] max-w-[336px] shadow-lg bg-white rounded-lg border border-gray-300 z-50"
              trigger={
                <p
                  onClick={() => {
                    navigator.clipboard.writeText(data.instructions);
                    setInstructionCopied((prevCopied) => ({
                      ...prevCopied,
                      [data.id]: true,
                    }));
                    setTimeout(() => {
                      setInstructionCopied((prevCopied) => ({
                        ...prevCopied,
                        [data.id]: false,
                      }));
                    }, 500);
                  }}
                  className="cursor-pointer text-sm font-bold text-navy-700"
                >
                  {data.instructions && data.instructions.slice(0, 150)}
                  {data.instructions && data.instructions.length > 150 && (
                    <span>...</span>
                  )}
                </p>
              }
              content={
                <p className="text-sm font-bold text-navy-700">
                  {data.instructions}
                </p>
              }
              placement="top"
            />
          </span>
        </div>
        <div className="flex items-center gap-2">
          <FaRegFileWord />
          <span className="text-md text-black">Word count: </span>
          <span className="text-sm font-bold text-navy-700">
            {data.wordCount}
          </span>
        </div>
        <button
          className="mt-2 w-full rounded-md bg-brand-500 px-4 py-2 text-sm font-medium text-white hover:opacity-80"
          onClick={handleAssignToMe}
        >
          Assign to me
        </button>
      </div>
    </Card>
  );
};

export default ArticleCard;
