import Card from 'components/card';
import avatar from 'assets/img/logo/Icon.png';

const Profile = ({ user }) => {
  return (
    <Card extra={'w-full py-[18px] px-[18px] h-[140px]'}>
      <div className="flex items-center gap-3">
        <div className="flex h-[90px] w-[90px] items-center justify-center rounded-full">
          <img className="h-full w-full rounded-full" src={avatar} alt="phot" />
        </div>
        <div>
          <p className="text-xl font-bold text-navy-700 dark:text-white">
            {user.firstName} {user.lastName}
          </p>
          <p className="text-base text-gray-600">{user.email}</p>
        </div>
      </div>
    </Card>
  );
};

export default Profile;
