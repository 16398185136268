import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { toastSuccess, toastError } from 'utils/toasts';
import { useLocation, useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { put, post } from 'api';
import Card from 'components/card';
import InputField from 'components/fields/InputField';
import Centered from 'layouts/auth/types/Centered';

function SignInCenter() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const emailConfirmationToken = params.get('confirmationToken');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const confirmEmail = async (token) => {
    const body = {
      token: token,
    };
    try {
      const res = await put('/confirm-email', body);
      if (res.status === 200) {
        toastSuccess('Email confirmed successfully and you can now login');
        setTimeout(() => {
          navigate('/');
        }, 2000);
      } else {
        const data = await res.json();
        toastError(data.message);
      }
    } catch (err) {
      console.log(err);
      toastError('Something went wrong');
    }
  };

  useEffect(() => {
    if (emailConfirmationToken) {
      confirmEmail(emailConfirmationToken);
    }
  }, [emailConfirmationToken]);

  const handleInfoChange = (e) => {
    const { id, value } = e.target;
    if (id === 'email') {
      setEmail(value);
    } else if (id === 'password') {
      setPassword(value);
    }
  };

  const handleSignIn = async () => {
    setIsSubmitting(true);
    const body = {
      email: email,
      password: password,
    };
    try {
      const res = await post('/auth/login', body);
      if (res.status === 200) {
        const data = await res.json();
        const decodedToken = jwt_decode(data.token);
        localStorage.setItem('token', data.token);
        localStorage.setItem('userId', decodedToken.userId);
        localStorage.setItem('userRole', decodedToken.role);
        localStorage.setItem('userFullName', decodedToken.fullName);
        localStorage.setItem('userEmail', decodedToken.email);
        toastSuccess('Logged in successfully');
        setTimeout(() => {
          setIsSubmitting(false);
          setEmail('');
          setPassword('');
          navigate('/admin/dashboard/articles');
        }, 2000);
      } else {
        const data = await res.json();
        toastError(data.message);
      }
    } catch (err) {
      console.log(err);
    }
    setIsSubmitting(false);
  };

  return (
    <Centered
      bgImage="linear-gradient(135deg, #868CFF 0%, #4318FF 100%)"
      maincard={
        <Card extra="max-w-[405px] md:max-w-[510px] md:w-[510px] h-max mx-2.5 md:mx-auto mt-[50px] mb-auto py-2.5 px-4 md:!p-[50px] pt-8 md:pt-[50px]">
          <ToastContainer
            position="top-center"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <h3 className="mb-[10px] text-4xl font-bold text-gray-900 dark:text-white">
            Sign In
          </h3>
          <p className="mb-9 ml-1 text-base text-gray-600">
            Enter your email and password to sign in!
          </p>
          <InputField
            variant="auth"
            extra="mb-3"
            label="Email*"
            placeholder="mail@simmmple.com"
            id="email"
            type="text"
            value={email}
            onChange={handleInfoChange}
          />
          <InputField
            variant="auth"
            extra="mb-3"
            label="Password*"
            placeholder="Min. 8 characters"
            id="password"
            type="password"
            value={password}
            onChange={handleInfoChange}
          />
          <button
            onClick={() => handleSignIn()}
            className="mt-4 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <div className="flex items-center justify-center">
                <div className="mr-2 h-4 w-4 animate-spin rounded-full border-b-2 border-white" />
                <span>Sign In..</span>
              </div>
            ) : (
              <span>Sign In</span>
            )}
          </button>
          <div className="mt-3">
            <span className="text-sm font-medium text-navy-700 dark:text-gray-500">
              Forgot password?
            </span>
            <a
              href="/forgot-password"
              className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-500 dark:text-white"
            >
              Reset Password
            </a>
          </div>
          {/* <div className="mt-3">
            <span className="text-sm font-medium text-navy-700 dark:text-gray-500">
              Not registered yet?
            </span>
            <a
              href="/sign-up"
              className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-500 dark:text-white"
            >
              Create an Account
            </a>
          </div> */}
        </Card>
      }
    />
  );
}

export default SignInCenter;
