import React from 'react';
import InputField from 'components/fields/InputField';

const CompanyDetails = ({ billingInfo, updateBillingInfo }) => {
  const { accountName, billingEmail, bankName, iban } = billingInfo;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateBillingInfo({ [name]: value });
  };

  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
      {/* Header */}
      <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        Your billing account details
      </h4>
      {/* inputs */}
      <div className="mt-4">
        <InputField
          label="Account Name"
          placeholder="eg. John Doe"
          id="accountName"
          name="accountName"
          onChange={handleInputChange}
          value={accountName || ''}
        />
      </div>
      <div className="mt-2">
        <InputField
          label="Billing Email"
          placeholder="eg. email@email.com"
          id="billingEmail"
          name="billingEmail"
          onChange={handleInputChange}
          value={billingEmail || ''}
        />
      </div>
      <div className="mt-2">
        <InputField
          label="Bank Name"
          placeholder="eg. Bank of America"
          id="bankName"
          name="bankName"
          onChange={handleInputChange}
          value={bankName || ''}
        />
      </div>
      <div className="mt-2">
        <InputField
          label="IBAN"
          placeholder="eg. 123456789"
          id="iban"
          name="iban"
          onChange={handleInputChange}
          value={iban || ''}
        />
      </div>
    </div>
  );
};

export default CompanyDetails;
