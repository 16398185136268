import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const PrivateRoute = ({ children, ...rest }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticated = localStorage.getItem('token') !== null;

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate, location]);

  if (!isAuthenticated) {
    return null;
  } else {
    return children;
  }
};

export default PrivateRoute;
